import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import FittingPage from './pages/FittingPage';
import {useState, useEffect, useMemo, useContext} from 'react';
import FaqPage from './pages/FaqPage';
import FourOFour from './pages/FourOFour';
import {theme} from './muiTheme';
import {embedTheme} from './muiThemeEmbed';
import {ThemeProvider, Typography, Box, LinearProgress} from '@mui/material';
import createTheme from '@mui/material/styles/createTheme';
import LocalizedStrings from 'react-localization';
import localizations from './localization/localizations';
import useImagePreloader from './util/useImagePreloader';
import {RegionDispatchContext} from './RegionContext';
import iso3166 from 'iso-3166-2';
//
const colors = ['#000000', '#FFFFFF', '#007A33', '#292929', '#F1F3F9', '#9de5a4', '#ddffe0', '#3F444D', '#151515', '#3f3f3f', '#00ff6b'];
const preloadSrcList = [
    'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/yardageReport07_BG_opt_size.jpg',
    /* 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/',  CLUBS HERE if needed*/
    'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/Juniors_Walking.jpg',
    'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/PING_Webfit_Junior_Logo.png',
    'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/Goodlie.png',
    'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/Badlie.png',
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/Height.svg`,
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/HeightWtfPerson.svg`,
    'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/Wristcrease.png',
    'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/Wtf.svg',
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Large_Black_Spine.png`,
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Large_Black.png`,
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Large_Black_Side.png`,
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Large_White_Spine.png`,
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Large_White.png`,
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Large_White_Side.png`,
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Small_Black_Spine.png`,
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Small_Black.png`,
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Small_Black_Side.png`,
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Small_White_Spine.png`,
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Small_White.png`,
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_2024_Bag_Small_Side.png`,
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/anserAddressBall.png`,
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/anserPutterLayingFlat.png`,
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/anserCavity.png`,
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/tyneAddressBall.png`,
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/tynePutterLayingFlat.png`,
    `https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/tyneCavity.png`,
    'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/Heroirons.png',
    'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ProdiG_Lifestyle_2024_1.jpg',
    'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/us-flag.svg',
    'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/gb-flag.svg',
    'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ca-flag.svg'
];

function App() {
    const [selectedTheme, setSelectedTheme] = useState(embedTheme);
    const [speedUnitImperial, setSpeedUnitImperial] = useState(true);
    const [distanceUnitImperial, setDistanceUnitImperial] = useState(true);
    const [isReturnData, setIsReturnData] = useState(false);
    const location = useLocation();
    const {pathname} = location;
    const {imagesPreloaded} = useImagePreloader(preloadSrcList);
    const setRegionState = useContext(RegionDispatchContext);

    //Localization stuff
    let translations = useMemo(() => {
        return new LocalizedStrings(localizations);
    }, []);
     // Separate function to load Segment script based on user's country
     const loadSegmentScript = (writeKey) => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = `https://cdn.segment.com/analytics.js/v1/${writeKey}/analytics.min.js`;
        document.head.appendChild(script);

        script.onload = () => {
            const analytics = window.analytics || [];
            if (!analytics.initialize) {
                if (analytics.invoked) {
                    window.console && console.error && console.error("Segment snippet included twice.");
                } else {
                    analytics.invoked = true;
                    analytics.methods = [
                        "trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify",
                        "reset", "group", "track", "ready", "alias", "debug", "page", "screen", "once",
                        "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId",
                        "addDestinationMiddleware", "register"
                    ];
                    analytics.factory = function(method) {
                        return function() {
                            const args = Array.prototype.slice.call(arguments);
                            args.unshift(method);
                            analytics.push(args);
                            return analytics;
                        };
                    };
                    for (let i = 0; i < analytics.methods.length; i++) {
                        const method = analytics.methods[i];
                        analytics[method] = analytics.factory(method);
                    }
                    analytics.load = function(key) {
                        const script = document.createElement("script");
                        script.type = "text/javascript";
                        script.async = true;
                        script.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
                        const first = document.getElementsByTagName("script")[0];
                        first.parentNode.insertBefore(script, first);
                        analytics._loadOptions = {};
                    };
                    analytics.load(writeKey);
                    analytics.page();
                }
            }
        };
    };
    const mockAnalytics = {
        track: () => {},
        page: () => {},
        identify: () => {}
        // Add other methods if needed
    };
     // Effect to load Segment script based on user's location
     useEffect(() => {
        try {
            if(window.Osano){
                const countryCode = window.Osano.cm.jurisdiction;
                const country = iso3166.subdivision(countryCode).countryCode;
                if (country === 'US') {
                    loadSegmentScript(process.env.REACT_APP_SEGMENT_WRITE_KEY);
                }
                else {
                    window.analytics = mockAnalytics;
                }
            }
            else {
                window.analytics = mockAnalytics;
            }
        } catch (error) {
            console.log(error);
            window.analytics = mockAnalytics;
        }
    }); 

    useEffect(() => {
        // List of European language codes
        const europeanLanguages = [
            'bg',
            'bg-BG',
            'cs',
            'cs-CZ',
            'da',
            'da-DK',
            'de',
            'de-DE',
            'de-AT',
            'de-CH',
            'de-LI',
            'el',
            'el-GR',
            'en-GB',
            'en-IE',
            'en-MT',
            'et',
            'et-EE',
            'fi',
            'fi-FI',
            'hr',
            'hr-HR',
            'hu',
            'hu-HU',
            'it',
            'it-IT',
            'it-CH',
            'it-SM',
            'it-VA',
            'lt',
            'lt-LT',
            'lv',
            'lv-LV',
            'mt',
            'mt-MT',
            'nl',
            'nl-NL',
            'nl-BE',
            'pl',
            'pl-PL',
            'pt',
            'pt-PT',
            'pt-PT',
            'ro',
            'ro-RO',
            'sk',
            'sk-SK',
            'sl',
            'sl-SI',
            'tr',
            'tr-TR'
        ];

        const canadianLanguages = ['en-CA', 'fr-CA'];
        /* const frenchEuropeanLanguages = ['fr', 'fr-FR', 'fr-BE', 'fr-CH', 'fr-LU', 'fr-MC'];
        const japaneseLanguages = ['ja', 'ja-JP'];
        const swedishLanguages = ['sv', 'sv-SE', 'sv-FI'];
        const spanishLanguages = ['es','es-ES']
 */
        let preSetRegionString = localStorage.getItem(`PingJuniorWebFitPreSetRegion`);

        let preSetRegion = JSON.parse(preSetRegionString);
        if (!preSetRegion || !preSetRegion?.language) {
            let language = navigator.language || navigator.userLanguage;
            if (!language) language = 'en';
            if (europeanLanguages.includes(language)) {
                setRegionState({name: 'English/GB', img: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/gb-flag.svg', language: 'en'});
            }
            if (canadianLanguages.includes(language)) {
                setRegionState({name: 'English/CA', img: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/ca-flag.svg', language: 'en'});
            }
            /* if (frenchEuropeanLanguages.includes(language)) {
                setRegionState({name: 'Français/FR', img: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/fr-flag.svg', language: 'fr'});
            }
            if (japaneseLanguages.includes(language)) {
                setRegionState({name: '地域/言語', img: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/jp-flag.svg', language: 'ja'});
            }
            if (swedishLanguages.includes(language)) {
                setRegionState({name: 'Svenska/SE', img: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/se-flag.svg', language: 'sv'});
            }
            if(spanishLanguages.includes(language)){
                setRegionState({name: 'Español/ES', img: 'https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/es-flag.svg', language: 'es'});
            } */
            language = language.substr(0, 2);
            translations.setLanguage(language);
            return;
        } else {
            setRegionState(preSetRegion);
            translations.setLanguage(preSetRegion.language);
        }
    }, [translations, setRegionState]);

    useEffect(() => {
        let speedSystem = localStorage.getItem(`PingJuniorWebFitSpeed`);
        if (!speedSystem || speedSystem === 'true') {
            setSpeedUnitImperial(true);
            localStorage.setItem(`PingJuniorWebFitSpeed`, 'true');
        } else {
            setSpeedUnitImperial(false);
            localStorage.setItem(`PingJuniorWebFitSpeed`, 'false');
        }

        let distanceSystem = localStorage.getItem(`PingJuniorWebFitDistance`);
        if (!distanceSystem || distanceSystem === 'true') {
            setDistanceUnitImperial(true);
            localStorage.setItem(`PingJuniorWebFitDistance`, 'true');
        } else {
            setDistanceUnitImperial(false);
            localStorage.setItem(`PingJuniorWebFitDistance`, 'false');
        }
    }, []);

    useEffect(() => {
        if (pathname.includes('embed')) {
            setSelectedTheme(embedTheme);
        } else {
            setSelectedTheme(theme);
        }
        return;
    }, [pathname]);

    // useEffect(() => {
    //     console.log('Images Preloaded');
    //     console.log(imagesPreloaded);
    // }, [imagesPreloaded]);

    if (!imagesPreloaded)
        return (
            <ThemeProvider theme={createTheme(selectedTheme)}>
                <Box
                    sx={{
                        color: colors[1],
                        width: '90%',
                        height: '100vh',
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '0% 5%',
                        gap: '10px'
                    }}
                >
                    <Typography variant='boldCopy' sx={{textAlign: 'center', fontSize: '1rem'}}>
                        {translations.LoadingAppFirstLine}
                    </Typography>
                    <Typography variant='mediumCopy' sx={{textAlign: 'center', fontSize: '0.8rem'}}>
                        {translations.LoadingAppSecondLine}
                    </Typography>
                    <LinearProgress sx={{width: '200px', marginTop: '20px', opacity: 0.5}} />
                </Box>
            </ThemeProvider>
        );

    return (
        <>
            <ThemeProvider theme={createTheme(selectedTheme)}>
                <Routes>
                    <Route
                        exact
                        path='/'
                        element={
                            <LandingPage
                                translations={translations}
                                embed={false}
                                colors={colors}
                                speedUnitImperial={speedUnitImperial}
                                setSpeedUnitImperial={setSpeedUnitImperial}
                                distanceUnitImperial={distanceUnitImperial}
                                setDistanceUnitImperial={setDistanceUnitImperial}
                                setIsReturnData={setIsReturnData}
                            />
                        }
                    />
                    <Route
                        exact
                        path='/fitting'
                        element={
                            <FittingPage
                                translations={translations}
                                embed={false}
                                colors={colors}
                                speedUnitImperial={speedUnitImperial}
                                setSpeedUnitImperial={setSpeedUnitImperial}
                                distanceUnitImperial={distanceUnitImperial}
                                setDistanceUnitImperial={setDistanceUnitImperial}
                                setIsReturnData={setIsReturnData}
                                isReturnData={isReturnData}
                            />
                        }
                    />
                    <Route exact path='/faq' element={<FaqPage translations={translations} embed={false} colors={colors} />} />
                    <Route exact path='/404' element={<FourOFour translations={translations} embed={false} colors={colors} />} />
                    <Route
                        exact
                        path='/embed'
                        element={
                            <LandingPage
                                translations={translations}
                                embed={true}
                                colors={colors}
                                speedUnitImperial={speedUnitImperial}
                                setSpeedUnitImperial={setSpeedUnitImperial}
                                distanceUnitImperial={distanceUnitImperial}
                                setDistanceUnitImperial={setDistanceUnitImperial}
                                setIsReturnData={setIsReturnData}
                            />
                        }
                    />

                    <Route
                        exact
                        path='/embed/fitting'
                        element={
                            <FittingPage
                                translations={translations}
                                embed={true}
                                colors={colors}
                                speedUnitImperial={speedUnitImperial}
                                setSpeedUnitImperial={setSpeedUnitImperial}
                                distanceUnitImperial={distanceUnitImperial}
                                setDistanceUnitImperial={setDistanceUnitImperial}
                                setIsReturnData={setIsReturnData}
                                isReturnData={isReturnData}
                            />
                        }
                    />
                    <Route exact path='/embed/faq' element={<FaqPage translations={translations} embed={true} colors={colors} />} />
                    <Route exact path='/embed/404' element={<FourOFour translations={translations} embed={true} colors={colors} />} />
                    <Route path='*' element={<Navigate to='/' replace />} />
                </Routes>
            </ThemeProvider>
        </>
    );
}

export default App;
