import React, {useState, useEffect} from 'react';
import download from 'downloadjs';
import {Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress} from '@mui/material';

import {transformSpecificSet} from '../util/Analytics';
function FullBagTableElements({
    translations,
    colors,
    selectedSet,
    flightData,
    distanceUnitImperial,
    setDownloadMalfunction,
    createYardageReport,
    createFittingReport,
    embed,
    putterPreference,
    playerLevel,
    bagPreference
}) {
    const [modifiedFlightData, setModifiedFlightData] = useState(null);
    const [downloadingReport, setDownloadingReport] = useState(false);

    async function downloadReport() {
        setDownloadingReport(true);
        const trackData = transformSpecificSet(flightData.lc_df, flightData.set_config_df, selectedSet, playerLevel, putterPreference, bagPreference);
        trackData['b2b'] = embed;
        if (window.analytics) {
            window.analytics.track('Fitting Results Download', trackData);
        }
        window.gtag('event', 'Fitting Results Download', trackData);
        try {
            const pdfBytes = await createYardageReport();

            const currentDate = new Date();

            const monthNames = [
                translations.January,
                translations.February,
                translations.March,
                translations.April,
                translations.May,
                translations.June,
                translations.July,
                translations.August,
                translations.September,
                translations.October,
                translations.November,
                translations.December
            ];

            const month = monthNames[currentDate.getMonth()];
            const day = currentDate.getDate();
            const year = currentDate.getFullYear();

            const formattedDate = `${month} ${day}, ${year}`;

            download(pdfBytes, `${translations.YardageReportFileName} ${formattedDate}.pdf`, 'application/pdf');
        } catch (e) {
            console.error(e);
            setDownloadingReport(false);
            setDownloadMalfunction(true);
        }
        setDownloadingReport(false);
    }

    useEffect(() => {
        let arr = [];
        if (!flightData) return;
        if (!flightData?.lc_df) return;
        if (!flightData?.set_config_df) return;
        if (!selectedSet) return;
        let copy = JSON.parse(JSON.stringify(flightData.lc_df));

        //remove putter and bag from flights
        for (let i = 0; i < copy.length - 2; i++) {
            if (flightData?.set_config_df[0][selectedSet].includes(copy[i].Club)) {
                arr.push(copy[i]);
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (i === arr.length - 1) {
                arr[i].Gap = '-';
                continue;
            }
            if (arr[i].Gap.includes('T')) {
                let tempT = (arr[i].Total - arr[i + 1].Total).toFixed(1);
                arr[i].Gap = tempT + 'T';
                continue;
            }

            arr[i].Gap = (arr[i].Total - arr[i + 1].Total).toFixed(1);
            continue;
        }
        setModifiedFlightData(arr);
    }, [flightData, selectedSet]);

    return (
        <>
            <Box
                sx={{
                    marginTop: {xs: '1rem', md: '3rem'},
                    display: 'flex',
                    justifyContent: {xs: 'flex-start', md: 'space-between'},
                    alignItems: {xs: 'center', md: 'flex-start'},
                    flexDirection: {xs: 'column', md: 'row'},
                    maxWidth: '90vw',
                    width: '1200px',
                    position: 'relative',
                    minHeight: '484px'
                }}
            >
                <Box
                    sx={{
                        minWidth: '360px',
                        width: '456px',
                        maxWidth: '38%',
                        height: '100%',
                        display: {xs: 'none', md: 'flex'},
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <Typography sx={{textAlign: 'center', margin: '0rem 1rem', fontSize: '1rem'}}>{translations.GappingTableDownloadInfoCopy}</Typography>
                    <Button
                        onClick={downloadReport}
                        disabled={downloadingReport}
                        variant='contained'
                        sx={{
                            maxWidth: '90%',
                            width: '90%',
                            height: {xs: '60px', lg: '45px'},
                            padding: '10px 20px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            letterSpacing: '0.8px',
                            marginBottom: '2rem',
                            marginTop: '2rem'
                        }}
                    >
                        {downloadingReport ? <CircularProgress size={'20px'} /> : <> {translations.GappingTableDownloadButton}</>}
                    </Button>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', flexDirection: 'column'}}>
                        <Typography sx={{width: '100%', textAlign: 'left', margin: '1.5rem 0rem 1rem 1rem', fontStyle: 'italic', fontSize: '0.7rem'}}>
                            {' '}
                            *{translations.GappingTableDisclaimer1}
                        </Typography>
                        <Typography sx={{fontStyle: 'italic', textAlign: 'left', margin: '1rem', fontSize: {xs: '0.8rem', md: '0.7rem'}}}>
                            **{translations.GappingTableDisclaimer2}
                        </Typography>
                    </Box>
                </Box>
                <TableContainer component={Paper} sx={{width: '700px', maxWidth: {xs: '100%', md: '53%', lg: '60%'}, overflowX: 'hidden', minHeight: '482px'}}>
                    <Table aria-label={translations.GappingTableAriaLabel} sx={{maxWidth: '100%', overflowX: 'hidden'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant='boldCopy' sx={{fontSize: '1rem'}}>
                                        {translations.GappingTableColumn1Title}
                                    </Typography>
                                </TableCell>
                                <TableCell align='right'>
                                    <Typography variant='boldCopy' sx={{fontSize: '1rem'}}>
                                        {translations.GappingTableColumn2Title} ({distanceUnitImperial ? translations.YardUnit : translations.MeterUnit})
                                    </Typography>
                                </TableCell>
                                <TableCell align='right'>
                                    <Typography variant='boldCopy' sx={{fontSize: '1rem'}}>
                                        {translations.GappingTableColumn3Title} ({distanceUnitImperial ? translations.YardUnit : translations.MeterUnit})
                                    </Typography>
                                </TableCell>
                                <TableCell align='right'>
                                    <Typography variant='boldCopy' sx={{fontSize: '1rem'}}>
                                        {translations.GappingTableColumn4Title} ({distanceUnitImperial ? translations.YardUnit : translations.MeterUnit})
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {flightData?.set_config_df[0] &&
                                selectedSet &&
                                modifiedFlightData?.map(club => {
                                    if (flightData?.set_config_df[0][selectedSet].includes(club.Club) && club.isAFlightClub) {
                                        return (
                                            <>
                                                <TableRow key={club.Club} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                    <TableCell
                                                        component='th'
                                                        scope='row'
                                                        sx={{
                                                            display: {xs: 'none', sm: 'flex'},
                                                            minWidth: '150px',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            fontSize: '1rem'
                                                        }}
                                                    >
                                                        <img
                                                            src={`https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/${club.image}`}
                                                            alt={club.Club}
                                                            style={{height: '25px', width: '25px', objectFit: 'contain', marginRight: '1rem'}}
                                                        />
                                                        {translations[club?.ClubName]
                                                            ? translations[club?.ClubName]
                                                            : club?.ClubName.includes('iron')
                                                            ? club?.ClubName.replace(/iron/g, translations.Iron)
                                                            : club?.ClubName === 'Pitching Wedge'
                                                            ? 'PW'
                                                            : club?.ClubName}
                                                    </TableCell>
                                                    <TableCell component='th' scope='row' sx={{fontSize: '1rem', display: {xs: 'flex', sm: 'none'}}}>
                                                        {translations[club?.ClubName]
                                                            ? translations[club?.ClubName]
                                                            : club?.ClubName.includes('iron')
                                                            ? club?.ClubName.replace(/iron/g, translations.Iron)
                                                            : club?.ClubName === 'Pitching Wedge'
                                                            ? 'PW'
                                                            : club?.ClubName}
                                                    </TableCell>
                                                    <TableCell sx={{fontSize: '1rem'}} align='right'>
                                                        {club?.Carry}
                                                    </TableCell>
                                                    <TableCell sx={{fontSize: '1rem'}} align='right'>
                                                        {club?.Total}
                                                    </TableCell>

                                                    <TableCell sx={{fontSize: '1rem'}} align='right'>
                                                        {Math.round(club?.Gap) || '-'}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        );
                                    } else {
                                        return <></>;
                                    }
                                })}
                            <>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell
                                        component='th'
                                        scope='row'
                                        sx={{
                                            display: {xs: 'none', sm: 'flex'},
                                            minWidth: '150px',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            fontSize: '1rem'
                                        }}
                                    >
                                        <img
                                            src={`https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/${putterPreference.toLowerCase()}Cavity.png`}
                                            alt={'Putter'}
                                            style={{height: '25px', width: '25px', objectFit: 'contain', marginRight: '1rem'}}
                                        />{' '}
                                        {translations.Putter}
                                    </TableCell>
                                    <TableCell component='th' scope='row' sx={{fontSize: '1rem', display: {xs: 'flex', sm: 'none'}}}>
                                        {translations.Putter}
                                    </TableCell>
                                    <TableCell sx={{fontSize: '1rem'}} align='right'>
                                        -
                                    </TableCell>
                                    <TableCell sx={{fontSize: '1rem'}} align='right'>
                                        -
                                    </TableCell>

                                    <TableCell sx={{fontSize: '1rem'}} align='right'>
                                        -
                                    </TableCell>
                                </TableRow>
                            </>
                        </TableBody>
                    </Table>
                    <Typography
                        sx={{
                            display: {xs: 'flex', md: 'none'},
                            width: '100%',
                            textAlign: 'left',
                            margin: '1.5rem 0rem 1rem 1rem',
                            fontStyle: 'italic',
                            fontSize: '0.7rem'
                        }}
                    >
                        {' '}
                        *{translations.GappingTableDisclaimer1}
                    </Typography>
                    <Typography
                        sx={{display: {xs: 'flex', md: 'none'}, fontStyle: 'italic', textAlign: 'left', margin: '1rem', fontSize: {xs: '0.7rem', md: '0.7rem'}}}
                    >
                        **{translations.GappingTableDisclaimer2}
                    </Typography>
                </TableContainer>
                <Box sx={{display: {xs: 'flex', md: 'none'}, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <Typography sx={{textAlign: 'center', margin: '1.5rem 1rem', fontSize: '1rem', maxWidth: '90vw'}}>
                        {translations.GappingTableDownloadInfoCopy}
                    </Typography>
                    <Button
                        onClick={downloadReport}
                        disabled={downloadingReport}
                        variant='contained'
                        sx={{
                            maxWidth: '90vw',
                            width: '456px',
                            height: {xs: '60px', lg: '45px'},
                            padding: '10px 20px',
                            letterSpacing: '0.8px',
                            marginBottom: '2rem',
                            marginTop: '0.5rem'
                        }}
                    >
                        {downloadingReport ? <CircularProgress size={'20px'} /> : <> {translations.GappingTableDownloadButton}</>}
                    </Button>
                </Box>
            </Box>
        </>
    );
}

export default FullBagTableElements;
