import en from './en.json';
import ja from './ja.json';
import fr from './fr.json';
import sv from './sv.json';
import es from './es.json';

const translations = {
    en,
    ja,
    fr,
    sv,
    es
};

export default translations;
