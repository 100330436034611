import React, {useState} from 'react';
import {Box, ButtonBase} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
/* import CloseIcon from '@mui/icons-material/Close'; */
import SwitchUnitsElement from './SwitchUnitsElement';

function SettingsGroup({
    translations,
    colors,
    distanceUnitImperial,
    setDistanceUnitImperial,
    speedUnitImperial,
    setSpeedUnitImperial,
    playerDistance,
    setPlayerDistance,
    playerHeight,
    setPlayerHeight,
    wtf,
    setWtf,
    playerSwingSpeed,
    setPlayerSwingSpeed,
    createReturnData
}) {
    const [showSettings, setShowSettings] = useState(false);

    return (
        <Box
            sx={{
                position: 'absolute',
                maxWidth: '1180px',
                width: '100%',
                backgroundColor: 'transparent',
                top: '0px',
                display: {xs: 'flex', lg: 'flex'},
                justifyContent: 'flex-end',
                alignItems: 'flex-start'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '-0px',
                    right: {xs: '-2px', sm: '25px'},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-start',
                    width: {xs: 'calc(100% - 50px)', sm: '200px'},
                    marginRight: '0px'
                }}
            >
                <ButtonBase
                    className="globalSettings"
                    aria-label={translations.SettingsButtonAria}
                    sx={{
                        width: '30px',
                        height: '30px',
                        color: colors[1],
                        backgroundColor: colors[2],
                        borderBottomLeftRadius: `4px`,
                        borderBottomRightRadius: {xs: '0px', sm: '4px'},
                        textTransform: 'uppercase'
                    }}
                    variant='contained'
                    onClick={() => setShowSettings(!showSettings)}
                >
                    <SettingsIcon sx={{fontSize: '16px'}} />
                </ButtonBase>
                {showSettings && (
                    <Box
                        sx={{
                            width: '100%',
                            border: `1px solid ${colors[2]}`,
                            borderBottomRightRadius: '4px',
                            borderBottomLeftRadius: '4px',
                            borderTop: `${showSettings ? `1px solid ${colors[2]}` : '0px'}`,
                            boxSizing: 'border-box',
                            MozBoxSizing: 'border-box',
                            WebkitBoxSizing: 'border-box',
                            paddingBottom: '15px',
                            backgroundColor: colors[1],
                            borderRadius: '4px',
                            marginTop: '4px',
                            marginRight: {xs: '25px', sm: '0px'}
                        }}
                    >
                        <SwitchUnitsElement
                            translations={translations}
                            colors={colors}
                            marginB={false}
                            distanceUnitImperial={distanceUnitImperial}
                            setDistanceUnitImperial={setDistanceUnitImperial}
                            speedUnitImperial={speedUnitImperial}
                            setSpeedUnitImperial={setSpeedUnitImperial}
                            playerDistance={playerDistance}
                            setPlayerDistance={setPlayerDistance}
                            playerHeight={playerHeight}
                            setPlayerHeight={setPlayerHeight}
                            wtf={wtf}
                            setWtf={setWtf}
                            playerSwingSpeed={playerSwingSpeed}
                            setPlayerSwingSpeed={setPlayerSwingSpeed}
                            createReturnData={createReturnData}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default SettingsGroup;
