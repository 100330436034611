import React, {useContext} from 'react';
import {RegionStateContext} from '../RegionContext';
import config from '../config';
import {Drawer, Box, Typography, Button, IconButton, Tooltip} from '@mui/material';
import {Close, InfoOutlined} from '@mui/icons-material';

function OrderSlipDrawerElement({
    embed,
    translations,
    colors,
    open,
    toggleDrawer,
    includedInBag,
    setEmailDialog,
    createFittingReport,
    downloadingReport,
    playerHeight,
    distanceUnitImperial,
    playerDexterity
}) {
    const regionState = useContext(RegionStateContext);
    const regionConfig = config[regionState.name];
    const dexterityKey = {
        0: translations.DexterityOption1,
        1: translations.DexterityOption2
    };

    function extractBeforeFirstSpace(str) {
        const firstSpaceIndex = str.indexOf(' ');

        if (firstSpaceIndex === -1) {
            return str;
        }

        return str.substring(0, firstSpaceIndex);
    }

    function extractAfterFirstSpace(str) {
        const firstSpaceIndex = str.indexOf(' ');

        if (firstSpaceIndex === -1) {
            return '';
        }

        return str.substring(firstSpaceIndex + 1);
    }

    return (
        <>
            <Drawer
                open={open}
                anchor='right'
                onClose={() => {
                    toggleDrawer(false);
                }}
                sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: colors[9]
                    },
                    '& .MuiModal-backdrop': {
                        backgroundColor: 'rgba(0, 0, 0, 0.85)'
                    }
                }}
            >
                <Box role='presentation' sx={{width: '400px', maxWidth: '90vw', position: 'relative'}}>
                    <IconButton
                        onClick={() => {
                            toggleDrawer(false);
                        }}
                        sx={{position: 'absolute', top: '0.5rem', right: '5px', color: 'white'}}
                    >
                        <Close />
                    </IconButton>
                    <Typography
                        variant='semiBoldCopy'
                        style={{
                            margin: '1.5rem 0 0.5rem 0',
                            width: '100%',
                            textAlign: 'center',
                            fontSize: '1.25rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: colors[1]
                        }}
                    >
                        {translations.OrderSlipDrawerTitle}
                    </Typography>
                    <Box
                        style={{
                            margin: '0 0 1rem 0',
                            width: '100%',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '0.9rem',
                                color: colors[1],
                                border: `1.6px solid ${colors[2]}`,
                                padding: '0px 10px',
                                borderRadius: '100px'
                            }}
                        >
                            {translations.Dexterity}: {dexterityKey[playerDexterity]}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            flexDirection: 'column',
                            gap: '20px',
                            width: '80%',
                            marginLeft: '10%',
                            paddingBottom: '2rem'
                        }}
                    >
                        {includedInBag?.map((e, i) => {
                            return (
                                <>
                                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '70px'}}>
                                        <img
                                            src={`https://pingwebfitjuniorstorage.blob.core.windows.net/dev-assets/${e.image}`}
                                            alt={`Club ${e.ClubName}OrderSlip`}
                                            style={
                                                e.ClubName.includes('Iron') || e.ClubName.includes('Wedge')
                                                    ? {height: '50px', width: '50px', padding: '5px', objectFit: 'contain'}
                                                    : {height: '60px', width: '60px', objectFit: 'contain'}
                                            }
                                        ></img>
                                        <Box
                                            sx={{
                                                marginLeft: '1rem',
                                                flexDirection: 'column',
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                height: '100%',
                                                flexGrow: 1,
                                                color: 'white'
                                            }}
                                        >
                                            <Typography
                                                key={`orderSlipItem${i}`}
                                                variant='semiBoldCopy'
                                                sx={{
                                                    width: '95%',
                                                    backgroundColor: colors[3],
                                                    fontSize: '0.8rem',
                                                    lineHeight: '1.25rem',
                                                    textAlign: 'center',
                                                    padding: '0.1rem 0',
                                                    color: colors[1],
                                                    marginBottom: '5px'
                                                }}
                                            >
                                                {e.ClubName}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    width: '95%',
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                {e.flex && (
                                                    <Typography variant='mediumCopy' sx={{fontSize: '0.8rem'}}>
                                                        {translations.OrderSlipShaftSpecTitle}: {e.flex}
                                                    </Typography>
                                                )}
                                                {e.variant && (
                                                    <Typography variant='mediumCopy' sx={{fontSize: '0.8rem'}}>
                                                        {e.ClubName.includes('Putter')
                                                            ? translations.OrderSlipModelSpecTitle
                                                            : translations.OrderSlipSizeSpecTitle}
                                                        : {translations[e.variant]}
                                                    </Typography>
                                                )}
                                                {e.lie && e.color && (
                                                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '0.2rem'}}>
                                                        <Typography
                                                            variant='mediumCopy'
                                                            sx={{fontSize: '0.8rem', marginRight: '2px', lineHeight: '0.8rem'}}
                                                        >
                                                            {translations.OrderSlipLieSpecTitle}: {extractBeforeFirstSpace(e.lie)}{' '}
                                                            {extractAfterFirstSpace(e.lie)}
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                width: '0.7rem',
                                                                height: '0.7rem',
                                                                borderRadius: '50%',
                                                                backgroundColor: e.color
                                                            }}
                                                        ></Box>
                                                    </Box>
                                                )}
                                                {e.preference && (
                                                    <Typography variant='mediumCopy' sx={{fontSize: '0.8rem'}}>
                                                        {translations.OrderSlipColorSpecTitle}: {translations[e.preference]}
                                                    </Typography>
                                                )}
                                                {e.risers >= 0 && (
                                                    <Box sx={{display: 'flex', justifyContnet: 'center', alignItems: 'center'}}>
                                                        <Typography variant='mediumCopy' sx={{fontSize: '0.8rem', lineHeight: '0.8rem'}}>
                                                            {translations.OrderSlipRisersSpecTitle}: {e.risers}
                                                        </Typography>
                                                        <Tooltip title={translations.RiserTooltip} enterTouchDelay={0} placement='top'>
                                                            <InfoOutlined sx={{color: colors[2], height: '15px'}} />
                                                        </Tooltip>
                                                    </Box>
                                                )}
                                                {e.length && (
                                                    <Typography variant='mediumCopy' sx={{fontSize: '0.8rem'}}>
                                                        {translations.OrderSlipLengthSpecTitle}: {e.length.replace(/STD"/g, 'STD')}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                </>
                            );
                        })}
                        <Button
                            variant='contained'
                            sx={{
                                width: '80%',
                                paddingTop: '10px',
                                marginTop: '0.5rem',
                                paddingBottom: '10px',
                                letterSpacing: '0.8px',
                                margin: '10px 0px 0px 0px',
                                height: '45px'
                            }}
                            disabled={downloadingReport}
                            onClick={async () => {
                                if(window.analytics){
                                    window.analytics.track('Order Now', {b2b: embed});
                                }
                                window.gtag('event', 'Order Now', {b2b: embed});
                                if (!embed && (regionState.name === 'English/US' || regionState.name === 'English/CA')) {
                                    if ((distanceUnitImperial && playerHeight <= 56) || (!distanceUnitImperial && playerHeight <= 142)) {
                                        window.open(regionConfig.orderOnlineShort, '_blank', '').focus();
                                    } else {
                                        window.open(regionConfig.orderOnlineTall, '_blank', '').focus();
                                    }
                                    return;
                                } else {
                                    regionConfig.resultsSecondCallToActionOnClick(setEmailDialog, createFittingReport, translations);
                                    return;
                                }
                            }}
                        >
                            {!embed && (regionState.name === 'English/US' || regionState.name === 'English/CA') ? (
                                <>{translations.OrderSlipCallToAction}</>
                            ) : (
                                <>{regionConfig.resultsSecondCallToActionCopy(translations, downloadingReport)}</>
                            )}
                        </Button>
                        {!embed && (regionState.name === 'English/US' || regionState.name === 'English/CA') ? (
                            <>
                                <Typography sx={{color: colors[1], textAlign: 'center', fontSize: '0.8rem'}}>{translations.OrderSlipOrderCopy1}</Typography>
                                <button
                                    onClick={() => {
                                        setEmailDialog(true);
                                    }}
                                    aria-label={translations.CompleteOrderLinkAriaLabel}
                                    tabIndex={1}
                                    style={{
                                        'all': 'unset',
                                        'position': 'relative',
                                        '&:focus': {
                                            color: colors[10]
                                        },
                                        'cursor': 'pointer',
                                        'transition': '0.3s all'
                                    }}
                                >
                                    <span style={{textDecoration: 'underline', fontSize: '0.8rem', fontWeight: 'bold', color: colors[1]}}>
                                        {translations.OrderSlipOrderCopy2}
                                    </span>
                                </button>{' '}
                            </>
                        ) : (
                            <Typography sx={{color: colors[1], textAlign: 'center', fontSize: '0.8rem', marginBottom: '0.7rem'}}>
                                {translations.EmbedOrderCopy}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Drawer>
        </>
    );
}

export default OrderSlipDrawerElement;
