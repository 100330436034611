import React, {useState, useEffect} from 'react';
import {Typography, Box, Button, Divider} from '@mui/material';
import SliderWithButtonsElement from '../elements/SliderWithButtonsElement';
import HowToWtfDialog from '../elements/HowToWtfDialog';
import CorrectLieAngleDialog from '../elements/CorrectLieAngleDialog';
import HeightSilhouetteElement from '../elements/HeightSilhouetteElement';
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';

const heightToWtf = [
    [48, 23],
    [49, 23],
    [50, 24],
    [51, 25],
    [52, 25],
    [53, 26],
    [54, 26],
    [55, 27],
    [56, 27],
    [57, 28],
    [58, 29],
    [59, 29],
    [60, 30],
    [61, 30],
    [62, 31]
];

function WristToFloorComponent({
    translations,
    colors,
    wtf,
    setWtf,
    distanceUnitImperial,
    showHowToLie,
    setShowHowToLie,
    playerHeight,
    wtfAlreadySet,
    setWtfAlreadySet
}) {
    const [showHowTo, setShowHowTo] = useState(false);
    const [suggestedWtf, setSuggestedWtf] = useState(0);
    const [moreInfo, setMoreInfo] = useState(false);

    function roundHalf(num) {
        return Math.round(num * 2) / 2;
    }

    useEffect(() => {
        const calcWtf = () => {
            let height;
            if (distanceUnitImperial) {
                height = playerHeight;
            } else {
                height = Math.round(playerHeight / 2.54);
            }
            let wtfVal;
            if (height < 48) {
                wtfVal = 23;
            } else if (height > 62) {
                wtfVal = 31;
            } else {
                let i = heightToWtf.findIndex(e => e[0] === height);
                wtfVal = heightToWtf[i][1];
            }

            if (distanceUnitImperial) {
                if (!wtfAlreadySet) {
                    setWtf(roundHalf(wtfVal));
                }
                setSuggestedWtf(roundHalf(wtfVal));
            } else {
                if (!wtfAlreadySet) {
                    setWtf(Math.round(wtfVal * 2.54));
                }
                setSuggestedWtf(Math.round(wtfVal * 2.54));
            }
        };

        calcWtf();

        setWtfAlreadySet(true);
    }, [distanceUnitImperial, setWtf, playerHeight, wtfAlreadySet, setWtfAlreadySet]);

    return (
        <>
            <HowToWtfDialog translations={translations} open={showHowTo} setOpen={setShowHowTo} />
            <CorrectLieAngleDialog translations={translations} colors={colors} open={showHowToLie} setOpen={setShowHowToLie} />
            <Box
                sx={{
                    animation: 'fadeInAnimation ease 1s',
                    animationIterationCount: 1,
                    animationFillMode: 'forwards',
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: colors[1],
                    textAlign: 'center'
                }}
            >
                <Typography
                    component='h2'
                    variant='semiBoldCopy'
                    sx={{
                        marginBottom: '20px',
                        fontSize: {xs: '1.25rem', md: '1.75rem'},
                        color: colors[7],
                        maxWidth: '90vw',
                        width: '700px',
                        padding: '20px 0px'
                    }}
                >
                    {translations.WtfTitle}
                </Typography>
                <Box
                    sx={{
                        maxWidth: '90vw',
                        width: '360px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginBottom: '30px'
                    }}
                >
                    <HeightSilhouetteElement colors={colors} distanceUnitImperial={distanceUnitImperial} playerHeight={playerHeight} wtf={wtf} />

                    <SliderWithButtonsElement
                        translations={translations}
                        metric={wtf}
                        setMetric={setWtf}
                        stepSize={0.5}
                        max={36}
                        min={18}
                        units={distanceUnitImperial ? 'inches' : 'cms'}
                        hideValue={true}
                        wtf={true}
                    />
                    <Typography sx={{fontSize: '0.8rem'}}>
                        {`${translations.PingEstimateWtf} `}
                        <span style={{color:colors[2]}}>{`${suggestedWtf} ${distanceUnitImperial ? translations.ToastInchTextPlural : translations.ToastCmText}.`}</span>
                    </Typography>
                    <Button variant='text' sx={{textTransform: 'none'}} onClick={() => setMoreInfo(!moreInfo)}>
                        {translations.MoreInfoButton}
                        {moreInfo ? <KeyboardArrowUp sx={{fontSize: '1rem'}} /> : <KeyboardArrowDown sx={{fontSize: '1rem'}} />}
                    </Button>
                    {moreInfo && (
                        <Typography sx={{fontSize: '0.7rem'}}>
                            {`${translations.WtfToast1} ${playerHeight}-${distanceUnitImperial ? translations.ToastInchText : translations.ToastCmText}-${
                                translations.WtfToast2
                            } ${suggestedWtf} ${distanceUnitImperial ? translations.ToastInchTextPlural : translations.ToastCmTextPlural}. ${
                                translations.WtfToast3
                            }`}
                        </Typography>
                    )}
                    <Divider sx={{width: '100%', margin: '10px 0px'}} />
                    <Typography sx={{fontSize: '0.8rem'}}>
                        {translations.WtfInfoCopy1}{' '}
                        <button
                            className='whyMattersButton'
                            onClick={() => setShowHowTo(true)}
                            aria-label={translations.HowToWristCreaseAriaLabel}
                            tabIndex='0'
                            style={{
                                'position': 'relative',
                                '&:focus': {
                                    color: colors[2]
                                },
                                'cursor': 'pointer',
                                'transition': '0.3s all'
                            }}
                        >
                            <span style={{textDecoration: 'underline', fontSize: '0.82rem', color: colors[2]}}>{translations.WtfInfoCopy2}</span>
                        </button>{' '}
                        {translations.WtfInfoCopy3}
                    </Typography>
                </Box>
            </Box>
        </>
    );
}

export default WristToFloorComponent;
